import React, { useRef, useEffect, useState } from 'react';
/** Components from ant design css framework */
import { Row, Col, Empty } from 'antd';

/** import icons from antd framework */

import CustomCheckbox from '../../../CustomCheckbox';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

/** PNG to put on users without image */

import { FixedSizeList as List } from 'react-window';

import cloneDeep from 'lodash/cloneDeep';

import { reversPathTasks } from '../../../../utils';

/** Redux implementation */
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  openNotification,
  categoriesConstraintType,
  renderIconCat,
  handshake
} from '../../../../utils';
/** Services */
import {
  constraintService,
  constraintScheduleService,
  notificationService
} from '../../../../services';

import { attachmentsService } from '../../../../services/attachment.service';
import { getSignedUser } from '../../../../utils/userUtils';
import { useConstraintPermission } from './hooks/useConstraintPermission';
import TaskColumn from './components/TaskColumn';
import { TimerManagerSingleton } from '../../../../utils/timerManager';

const LazyElement =
  (data) =>
  ({ index, style }) => (
    <div style={style} className="single-row-bottom-bordered">
      {data[index]}
    </div>
  );

export default function LookaheadOtherGroup(props) {
  const timerManager = TimerManagerSingleton.getInstance();
  const { permission, t } = props;
  const user = getSignedUser();
  const userProps = {
    id: user.id,
    permission: permission
  };

  const {
    groupParent,
    tableMetadata,
    updateAsyncConstraint,
    constraintActions,
    lastLevelActivities,
    modifyGroupBy
  } = props;
  const projectState = useSelector((state) => state.projectState);
  const [popsVisibility, setPopsVisibility] = useState({});
  const { height, width } = useWindowDimensions();
  const dispatch = useDispatch();
  const virtualizeRef = useRef();
  const [resourceMachine, setResourceMachine] = useState([]);
  const [resourceMaterial, setResourceMaterial] = useState([]);
  const [resourceRrhh, setResourceRrhh] = useState([]);
  const { attachments, setAttachments } = props;

  const viewCheckboxGroupPermission = useConstraintPermission(
    userProps,
    'constraint_checkbox_group',
    'update'
  );

  const sectorDateFormat = projectState.allSectors.find(
    (e) => e.id == projectState.sectorSelected
  );

  const formatDate =
    sectorDateFormat && sectorDateFormat.dateFormat
      ? sectorDateFormat.dateFormat
      : null;

  useEffect(() => {
    if (props.resources) {
      const mch = props.resources.filter((rs) => rs.type == 'machinery');
      const mtl = props.resources.filter((rs) => rs.type == 'material');
      const rrhh = props.resources.filter((rs) => rs.type == 'rrhh');
      setResourceMachine(mch);
      setResourceMaterial(mtl);
      setResourceRrhh(rrhh);
    }
  }, []);

  useEffect(() => {
    const id = props.groupParent.value;
    if (props.scrollStates[id]) {
      if (virtualizeRef.current) {
        virtualizeRef.current.scrollTo(props.scrollStates[id]);
      }
    }

    return () => {
      if (virtualizeRef.current) {
        props.scrollStates[id] = virtualizeRef.current.state.scrollOffset;
      }
    };
  }, [virtualizeRef]);

  /**
   * This functions shows a pretty alert to user
   * @param {*} data Object { title, message, type }
   */
  const notifyMessage = (data) => {
    const alertErrorMailExists = {
      title: data.title,
      description: data.message,
      type: data.type
    };
    openNotification(alertErrorMailExists);
  };

  /**
   * This function allows user to hide childs for a task or activity
   * @param {*} parent Parent activity or task to hide their children (REQUIRED)
   */
  const hideChilds = (parent) => {
    if (parent.tasks)
      props.virtualizeRef.current.resetAfterRowIndex(props.index);
    parent.hide_childs = true;

    const hideChildsTimerCallback = () => {
      props.updateRender(props.data);
    };
    timerManager.registerAutoTimeout(
      hideChildsTimerCallback,
      500,
      'hideChildsTimer'
    );
    updateState(false);
  };

  /**
   * This function allows user to show childs for a task or activity
   * @param {*} parent Parent activity or task to show their children (REQUIRED)
   */
  const showChilds = (parent) => {
    if (parent.tasks)
      props.virtualizeRef.current.resetAfterRowIndex(props.index);
    parent.hide_childs = false;

    const showChildsTimerCallback = () => {
      props.updateRender(props.data);
    };
    timerManager.registerAutoTimeout(
      showChildsTimerCallback,
      500,
      'showChildsTimer'
    );
    updateState(false);
  };

  /**
   * This function handles at virtual dom which icon should be shown (collapse or uncollapse)
   * @param {*} parent Parent activity or task to define what icon to show (REQUIRED)
   */
  const defineCollapseIcon = (parent) => {
    const children = parent.tasks || parent.children;
    if (children) {
      if (children.length) {
        if (parent.hide_childs) {
          return (
            <i
              onClick={() => showChilds(parent)}
              className="fa fa-angle-down show-childs-btn"
              aria-hidden="true"
            />
          );
        }
        return (
          <i
            onClick={() => hideChilds(parent)}
            className="fa fa-minus hide-childs-btn"
            aria-hidden="true"
          />
        );
      }
    }
  };

  const defineParentColumnContent = (column, parent) => {
    if (column.name == 'name') {
      if (parent.name || parent.label) {
        return (
          <div>
            {parent.tasks.length && viewCheckboxGroupPermission ? (
              <CustomCheckbox
                inverted
                onClick={() => {
                  props.massiveSelectionHandler(parent);
                  // updateState()
                }}
                active={parent.active}
              />
            ) : null}
            {defineCollapseIcon(parent)}
            <span style={{ marginLeft: 5 }}>{parent.name || parent.label}</span>
          </div>
        );
      }
      return parent.label;
    }
  };

  const renderParentColumn = (column, parent, index) => {
    if (column.visible) {
      return (
        <Col
          key={index}
          span={column.span}
          offset={column.offset}
          style={{
            textAlign: column.align,
            width: column.width ? column.width : null
          }}>
          {defineParentColumnContent(column, parent)}
        </Col>
      );
    }
  };

  const renderGroupParentHeader = (parent = groupParent) => (
    <Row className="custom-header-top-list">
      {tableMetadata.map((eachColumn, index) =>
        renderParentColumn(eachColumn, parent, index)
      )}
    </Row>
  );

  /**
   * Clone deep of a new state to set and render view
   * @param {*} newState new state value (inmutability is broken)
   */
  const updateState = (notifyLookahead = true) => {
    setPopsVisibility((prev) => {
      prev = cloneDeep(popsVisibility);
      return prev;
    });
    if (notifyLookahead) {
      dispatch(constraintActions.notifyLookaheadUpdate());
    }
  };

  /**
   * This function deals with pop visibility hash map
   * @param {boolean} visible Value to set the pop
   * @param {*} id Unique state eval to use at virtual DOM to show popup or hide it (from antd component)
   */

  const handleDelete = async (task, activity, parent = null) => {
    await constraintService.destroy(task.id);
    const newConstraints = activity.tasks.filter(
      (constraint) => constraint.id !== task.id
    );
    activity.tasks = newConstraints;

    notifyMessage({
      title: t('deleted_constraint_label'),
      message: t('deleted_constraint_message_label'),
      type: 'success'
    });
    props.virtualizeRef.current.resetAfterRowIndex(props.index);
    updateState();
    props.setMassiveSelection([]);
  };

  const renderTaskColumn = (column, task, index, parent) => {
    if (column.visible) {
      return (
        <Col
          className={
            column.name == 'name'
              ? 'fit-name-superposition'
              : 'single-task-column-style'
          }
          key={index}
          span={column.span}
          offset={column.offset}
          style={{
            paddingLeft: column.name == 'name' ? 15 : 0,
            textAlign: column.align,
            width: column.width ? column.width : null
          }}>
          <TaskColumn
            column={column}
            task={task}
            parent={parent}
            index={index}
            userProps={userProps}
            t={t}
            tableMetadata={tableMetadata}
            updateAsyncConstraint={updateAsyncConstraint}
            updateState={updateState}
            handleDelete={handleDelete}
            massiveSelectionHandler={props.massiveSelectionHandler}
            projectState={projectState}
            constraintActions={constraintActions}
            lastLevelActivities={lastLevelActivities}
            modifyGroupBy={modifyGroupBy}
            {...props}
          />
        </Col>
      );
    }
  };

  const renderChildTreeTasks = (finalArray, parent) => {
    parent.tasks.map((task) => {
      if (!task.hide) {
        finalArray.push(
          <Row
            style={{ backgroundColor: task.active ? '#12121210' : null }}
            key={task.id}
            className="fit-at-middle-row">
            {tableMetadata.map((eachColumn, index) =>
              renderTaskColumn(eachColumn, task, index, parent)
            )}
          </Row>
        );
      }
    });
  };

  /** This function is used for show tasks quantity on virtual dom who renders them and resize height */
  const defineVirtualizedHeight = (taskCounter) => {
    const tempHeight = taskCounter * 40;
    const maxHeight = height < 700 ? height * 0.45 : height * 0.6;
    if (tempHeight < maxHeight) {
      return tempHeight;
    }
    return maxHeight;
  };

  const renderTasks = (parent = groupParent) => {
    const childRended = [];
    renderChildTreeTasks(childRended, parent);

    parent.childRended = childRended.length;

    props.virtualizeRef.current.resetAfterRowIndex(props.index);
    /**
     * To Optimize render of this child list an option is
     * https://react-window.now.sh/#/examples/list/variable-size
     * with childRended, we can create a virtual list to handle performance
     */
    if (parent.hide_childs) {
      return null;
    } else if (childRended.length) {
      return (
        <List
          ref={virtualizeRef}
          height={defineVirtualizedHeight(childRended.length)}
          itemCount={childRended.length}
          itemSize={40}
          width={'100%'}>
          {LazyElement(childRended)}
        </List>
      );
    }
    return (
      <div style={{ marginTop: 20 }}>
        <Empty
          style={{ margin: 0 }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span></span>}
        />
      </div>
    );
  };

  /**
   * Render
   */

  if (!formatDate) return <div></div>;
  return (
    <Row key={props.index}>
      <Col
        className="tableConstraints"
        style={{ marginTop: 10, marginBottom: 40 }}>
        {renderGroupParentHeader(groupParent)}
        {renderTasks(groupParent)}
      </Col>
    </Row>
  );
}
