import { PERMISSION_RULES } from '../permissions/rules';

export function useConstraintPermission(user, resource, action, data) {
  const permission = PERMISSION_RULES[user.permission]?.[resource]?.[action];

  if (permission === undefined) return false;

  if (typeof permission === 'boolean') return permission;

  return data != null && permission(user.id, data);
}
