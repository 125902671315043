export const PERMISSION_RULES = {
  AC: {
    constraint: {
      view: true,
      create: true,
      update: true,
      delete: true
    },
    constraint_checkbox: {
      update: true
    },
    constraint_checkbox_group: {
      update: true
    }
  },
  V: {
    constraint: {
      view: true,
      create: true,
      update: false,
      delete: false
    },
    constraint_checkbox: {
      update: false
    },
    constraint_checkbox_group: {
      update: false
    }
  },
  ACP: {
    constraint: {
      view: true,
      create: true,
      update: (userId, responsableId) => {
        return userId === responsableId;
      },
      delete: (userId, responsableId) => {
        return userId === responsableId;
      }
    },
    constraint_checkbox: {
      update: false
    },
    constraint_checkbox_group: {
      update: false
    }
  }
};
